import * as yup from "yup";

import {
  Button,
  Center,
  CircularProgress,
  FormControl,
  FormErrorMessage,
  Input,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import {
  GET_ORGANISATION,
  UPDATE_ORGANISATION,
} from "../../../apollo/organisation";
import { useMutation, useQuery } from "@apollo/client";

import { BiBuildings } from "react-icons/bi";
import { GET_USER_DETAILS } from "../../../apollo/user";
import SettingsLayout from "../SettingsLayout";
import { useEffect } from "react";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import SettingsRow from "../SettingsRow";
import UsersTable from "../users/UsersTable";
import InviteUser from "../users/InviteUser";

type FormData = {
  name: string | null;
};

const schema = yup.object().shape({
  name: yup.string().min(4, "Too short"),
});

export default function OrganisationView() {
  const { data, loading, error, refetch } = useQuery(GET_ORGANISATION);
  const toast = useToast();

  useEffect(() => {
    if (data) {
      const organisation = data.me.organisation;
      setValue("name", organisation.name);
    }
  }, [data]);

  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      name: "",
    },
  });

  const [updateOrganisation, { loading: mutationLoading }] = useMutation(
    UPDATE_ORGANISATION,
    {
      refetchQueries: [{ query: GET_USER_DETAILS }, "GetUserDetails"],
      onCompleted: () => {
        toast({
          title: "Organisation updated",
          status: "success",
        });
      },
      onError(error) {
        error.graphQLErrors.forEach((error) => {
          switch (error.extensions.code) {
            default:
              toast({
                title: "Error occured",
                description: error.message,
                status: "error",
              });
          }
        });
      },
    }
  );

  const onSubmit = (data: FormData) => {
    if (isDirty)
      updateOrganisation({
        variables: {
          name: data.name,
        },
      });
  };

  if (loading) {
    return (
      <Center w="100%">
        <CircularProgress isIndeterminate color="blue.400" />
      </Center>
    );
  }

  if (error) {
    return null;
  }

  return (
    <SettingsLayout
      name={"Organisation"}
      description="Manage your organisations communication channels"
      actions={
        <Button
          variant="solid"
          colorScheme="blue"
          size="sm"
          type="submit"
          form="organisation-details"
          isLoading={mutationLoading}
        >
          Save Organisation
        </Button>
      }
    >
      <>
        <form id="organisation-details" onSubmit={handleSubmit(onSubmit)}>
          <SettingsRow
            title="Organisation Name"
            description="The name of your business"
            action={
              <FormControl isInvalid={!!errors.name}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <BiBuildings />
                  </InputLeftElement>
                  <Input
                    className="w-full"
                    placeholder="i.e Smartyr Biz"
                    type="name"
                    autoComplete="name"
                    id="name"
                    {...register("name")}
                  />
                </InputGroup>
                {!!errors.name && (
                  <FormErrorMessage>{errors.name.message}</FormErrorMessage>
                )}
              </FormControl>
            }
          />
        </form>

        <SettingsRow
          title="Users"
          description="Invite and manage users"
          action={<InviteUser />}
        />

        <UsersTable />
      </>
    </SettingsLayout>
  );
}
