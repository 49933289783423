import {
  ChartBarIcon,
  ChatBubbleOvalLeftIcon,
  Cog6ToothIcon,
  DevicePhoneMobileIcon,
  EnvelopeIcon,
  StarIcon,
  UsersIcon,
  ChatBubbleLeftRightIcon
} from "@heroicons/react/24/outline";
import { LiaFacebook, LiaFacebookMessenger } from "react-icons/lia";

export const CHANNELS_SELECT = {
  SMS: {
    name: "Text Message",
    icon: <DevicePhoneMobileIcon className="flex-shrink-0 w-5 h-5 mx-auto" />,
    buttonColors: "bg-purple-100 text-purple-800 hover:bg-purple-200",
  },
  REVIEW: {
    name: "Review",
    icon: <StarIcon className="w-5 h-5 mx-auto" />,
    buttonColors: "bg-amber-100 text-amber-800 hover:bg-amber-200",
  },
  EMAIL: {
    name: "Email",

    icon: <EnvelopeIcon className="w-5 h-5 mx-auto" />,
    buttonColors: "bg-green-100 text-green-800 hover:bg-green-200",
  },
  FACEBOOK_CHAT: {
    name: "Facebook Messenger",
    icon: <LiaFacebookMessenger className="w-5 h-5 mx-auto" />,
    buttonColors: "bg-blue-100 text-blue-800 hover:bg-blue-200",
  },
  FACEBOOK_RECOMMENDATION: {
    name: "Facebook Recommendation",
    icon: <LiaFacebook className="w-5 h-5 mx-auto" />,
    buttonColors: "bg-blue-100 text-blue-800 hover:bg-blue-200",
  },
  FEEDBACK: {
    name: "Feedback",
    icon: <UsersIcon className="w-5 h-5 mx-auto" />,
    buttonColors: "bg-green-100 text-green-800 hover:bg-green-200",
  },
  WEBCHAT: {
    name: "Webchat",
    icon: <ChatBubbleLeftRightIcon className="w-5 h-5 mx-auto" />,
    buttonColors: "bg-blue-100 text-blue-800 hover:bg-blue-200",
  },
};
