import "./App.css";
import {
  ApolloClient,
  HttpLink,
  ApolloLink,
  InMemoryCache,
  concat,
  split,
  ApolloProvider,
  fromPromise,
  Observable,
  FetchResult,
  NextLink,
  Operation,
} from "@apollo/client";
import RootNavigator from "./routes/RootNavigator";
import { useAuth } from "./hooks/useAuth";
import { GraphQLWsLink } from "@apollo/client/link/subscriptions";
import { getMainDefinition } from "@apollo/client/utilities";
import { createClient } from "graphql-ws";

function App() {
  const { token, refreshToken, refreshCurrentToken, logout } = useAuth();

  const ascii = [
    `╔═══════════════════════════════════════════════════════════════╗`,
    `║ ███████╗███╗   ███╗ █████╗ ██████╗ ████████╗██╗   ██╗██████╗  ║`,
    `║ ██╔════╝████╗ ████║██╔══██╗██╔══██╗╚══██╔══╝╚██╗ ██╔╝██╔══██╗ ║`,
    `║ ███████╗██╔████╔██║███████║██████╔╝   ██║    ╚████╔╝ ██████╔╝ ║`,
    `║ ╚════██║██║╚██╔╝██║██╔══██║██╔══██╗   ██║     ╚██╔╝  ██╔══██╗ ║`,
    `║ ███████║██║ ╚═╝ ██║██║  ██║██║  ██║   ██║      ██║   ██║  ██║ ║`,
    `║ ╚══════╝╚═╝     ╚═╝╚═╝  ╚═╝╚═╝  ╚═╝   ╚═╝      ╚═╝   ╚═╝  ╚═╝ ║`,
    `║                                                               ║`,
    `║             ██╗███╗   ██╗██████╗  ██████╗ ██╗  ██╗            ║`,
    `║             ██║████╗  ██║██╔══██╗██╔═══██╗╚██╗██╔╝            ║`,
    `║             ██║██╔██╗ ██║██████╔╝██║   ██║ ╚███╔╝             ║`,
    `║             ██║██║╚██╗██║██╔══██╗██║   ██║ ██╔██╗             ║`,
    `║             ██║██║ ╚████║██████╔╝╚██████╔╝██╔╝ ██╗            ║`,
    `║             ╚═╝╚═╝  ╚═══╝╚═════╝  ╚═════╝ ╚═╝  ╚═╝            ║`,
    `║                                                               ║`,
    `║ Stay safe, don't paste things into the console - Smartyr Team ║`,
    `╚═══════════════════════════════════════════════════════════════╝`,
  ];

  console.log(ascii.join("\n"));

  const httpLink = new HttpLink({
    uri: `${process.env.REACT_APP_API_URL}/graphql`,
  });

  const wsLink = new GraphQLWsLink(
    createClient({
      url: `ws://localhost:4444/graphql`,
      retryAttempts: 10,
      on: {
        connected: () => console.log("WebSocket connected"),
        closed: () => console.log("WebSocket closed"),
        message: (message) => console.log("WebSocket message:", message),
        error: (error) => console.error("WebSocket error", error),
      },
    })
  );

  const splitLink = split(
    ({ query }) => {
      const definition = getMainDefinition(query);
      return (
        definition.kind === "OperationDefinition" &&
        definition.operation === "subscription"
      );
    },
    wsLink,
    httpLink
  );

  const authMiddleware = new ApolloLink((operation, forward) => {
    operation.setContext(({ headers = {} }) => ({
      headers: {
        ...headers,
        authorization: `Bearer ${token}`,
      },
    }));
    return forward(operation);
  });

  const refreshTokenMiddleware = new ApolloLink(
    (operation: Operation, forward: NextLink) => {
      return new Observable<FetchResult>((observer) => {
        let handle: any;
        forward(operation).subscribe({
          next: (response) => {
            if (
              response.errors &&
              response.errors[0].message === "Unauthorized"
            ) {
              fromPromise(
                refreshCurrentToken()
                  .then(() => {
                    operation.setContext(({ headers = {} }) => ({
                      headers: {
                        ...headers,
                        authorization: `Bearer ${token}`,
                      },
                    }));
                    return forward(operation);
                  })
                  .catch((error) => {
                    logout();
                    return Observable.of(response);
                  })
              )
                .flatMap((observable) => observable)
                .subscribe({
                  next: observer.next.bind(observer),
                  error: observer.error.bind(observer),
                  complete: observer.complete.bind(observer),
                });
            } else {
              observer.next(response);
            }
          },
          error: observer.error.bind(observer),
          complete: observer.complete.bind(observer),
        });

        return () => {
          if (handle) handle.unsubscribe();
        };
      });
    }
  );

  const client = new ApolloClient({
    cache: new InMemoryCache(),
    link: concat(authMiddleware, concat(refreshTokenMiddleware, splitLink)),
  });

  return (
    <ApolloProvider client={client}>
      <RootNavigator />
    </ApolloProvider>
  );
}

export default App;
