import { Button, useToast } from "@chakra-ui/react";
import {
  CREATE_KEYWORD,
  DELETE_KEYWORD,
  GET_KEYWORDS,
  UPDATE_KEYWORD,
} from "../../../../apollo/keywords";
import { TrashIcon } from "@heroicons/react/24/outline";
import {
  Flex,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
} from "@chakra-ui/react";
import { Keyword, ModifiedKeyword } from "../../../../types/Keyword";

import {} from "@chakra-ui/react";

import { GET_TEMPLATES_AND_KEYWORDS } from "../../../../apollo/templates";
import { PlusIcon } from "@heroicons/react/24/solid";
import { keywordsStateVar } from "../../../../store/templates";
import { useMutation } from "@apollo/client";
import { useReactiveVar } from "@apollo/client";
import { v4 as uuidv4 } from "uuid";
type Props = {
  keywords: Keyword[];
};

export default function KeywordsView({ keywords }: Props) {
  const toast = useToast();
  const keywordsState = useReactiveVar(keywordsStateVar);

  /*   useEffect(() => {
    if (data && !!!data.keywords.length) {
      handleAddKeyword();
    }
  }, [keywords.length]);
 */
  const { isOpen, onOpen, onClose } = useDisclosure();
  function handleDelete(keyword: ModifiedKeyword) {
    const keywords = keywordsState;
    delete keywords[keyword.id];
    const newkeywords = keywords;

    keywordsStateVar({
      ...newkeywords,
    });
  }

  function handleAddKeyword() {
    const keywordId = uuidv4();

    keywordsStateVar({
      ...keywordsState,
      [keywordId]: {
        isNew: true,
        id: keywordId,
        key: "",
        value: "",
      },
    });
  }

  function handleSave() {
    Object.values(keywordsState).forEach((keyword: ModifiedKeyword) => {
      if (keyword.isNew) {
        createKeyword({
          variables: {
            keyword: {
              key: keyword.key.replace(/[{}]/g, ""),
              value: keyword.value,
            },
          },
        });
      }
    });

    keywords.forEach((item: Keyword) => {
      if (keywordsState[item.id]) {
        if (
          item.value !== keywordsState[item.id].value ||
          item.key !== keywordsState[item.id].key
        ) {
          updateKeyword({
            variables: {
              updateKeywordId: item.id,
              keyword: {
                key: keywordsState[item.id].key,
                value: keywordsState[item.id].value,
              },
            },
          });
        }
      } else {
        deleteKeyword({
          variables: {
            deleteKeywordId: item.id,
          },
        });
      }
    });
  }

  const [createKeyword, { loading: createLoading }] = useMutation(
    CREATE_KEYWORD,
    {
      refetchQueries: [{ query: GET_KEYWORDS }, "GetTemplatesAndKeywords"],
    }
  );

  const [deleteKeyword, { loading: deleteLoading }] = useMutation(
    DELETE_KEYWORD,
    {
      refetchQueries: [
        { query: GET_TEMPLATES_AND_KEYWORDS },
        "GetTemplatesAndKeywords",
      ],
      onError(error) {
        console.log(error);
        toast({
          title: "Error occured",
          description: error.message,
          status: "error",
        });
      },
    }
  );

  const [updateKeyword, { loading: updateLoading }] = useMutation(
    UPDATE_KEYWORD,
    {
      refetchQueries: [
        { query: GET_TEMPLATES_AND_KEYWORDS },
        "GetTemplatesAndKeywords",
      ],
      onCompleted: () => {
        toast({
          title: "Keyword updated",
          status: "success",
        });
      },
      onError(error) {
        toast({
          title: "Error occured",
          description: error.message,
          status: "error",
        });
      },
    }
  );
  console.log(keywordsState);
  return (
    <>
      <Button variant="outline" size="sm" onClick={onOpen}>
        Edit Keywords
      </Button>
      <Modal size="2xl" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Keywords</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            {!Object.values(keywordsState).length ? (
              <div className="max-w-md">
                <p>Add a keyword to get started</p>
                {/*             <p>
                  Keywords are for words in your template that
                  are likely to change such as user and contact names, discount
                  links and much more!
                </p> */}
              </div>
            ) : (
              <TableContainer>
                <Table size="sm">
                  <Thead>
                    <Tr>
                      <Th>Name</Th>
                      <Th>Value</Th>
                      <Th pl={0}></Th>
                    </Tr>
                  </Thead>
                  <Tbody>
                    {Object.values(keywordsState).map(
                      (keyword: ModifiedKeyword) => {
                        return (
                          <Tr key={keyword.id}>
                            <Td>
                              <Input
                                type="text"
                                form="keywords-form"
                                placeholder="Key"
                                value={keyword.key}
                                onChange={(e) => {
                                  keywordsStateVar({
                                    ...keywordsState,
                                    [keyword.id]: {
                                      ...keywordsState[keyword.id],
                                      key: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </Td>
                            <Td>
                              <Input
                                type="text"
                                form="keywords-form"
                                placeholder="Value"
                                value={keyword.value}
                                onChange={(e) => {
                                  keywordsStateVar({
                                    ...keywordsState,
                                    [keyword.id]: {
                                      ...keywordsState[keyword.id],
                                      value: e.target.value,
                                    },
                                  });
                                }}
                              />
                            </Td>
                            <Td pl={0}>
                              <IconButton
                                variant="ghost"
                                onClick={() => handleDelete(keyword)}
                                aria-label="Delete Keyword"
                              >
                                <TrashIcon className="h-4 w-4" />
                              </IconButton>
                            </Td>
                          </Tr>
                        );
                      }
                    )}
                  </Tbody>
                </Table>
              </TableContainer>
            )}

            <Flex justifyContent="space-between" mt={2}>
              <Button
                leftIcon={<PlusIcon className="w-4 h-4" />}
                variant="ghost"
                colorScheme="blue"
                size="sm"
                onClick={() => {
                  handleAddKeyword();
                }}
              >
                Add Keyword
              </Button>
            </Flex>
          </ModalBody>
          <ModalFooter>
            <Button variant="outline" mr={3} onClick={onClose}>
              Close
            </Button>
            <form id="keyword-form">
              <Button
                colorScheme="blue"
                type="submit"
                isLoading={createLoading || deleteLoading || updateLoading}
                onClick={() => handleSave()}
              >
                Save
              </Button>
            </form>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
