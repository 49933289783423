import {
  IconButton,
  Input,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Button,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import * as yup from "yup";

import { RiPhoneLine } from "react-icons/ri";
import { useMutation } from "@apollo/client";
import phone from "phone";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { PencilSquareIcon } from "@heroicons/react/24/outline";
import { useEffect } from "react";
import { UPDATE_PHONE } from "src/apollo/phone";
const schema = yup.object().shape({
  phone: yup
    .string()
    .required("Please enter a phone number")
    .test("isPhoneValid", "Invalid phone number", (value) =>
      value != undefined && value.length
        ? phone(value, { country: "AU" }).isValid
        : true
    ),
});

type FormData = {
  phone: string | null;
};

type Props = {
  phone: {
    id: string;
    name: string;
    phone: string;
  };
};

export default function UpdatePhone({ phone }: Props) {
  console.log(phone);
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: phone.phone,
    },
  });
  const onSubmit = (data: FormData) => {
    console.log("fired");
    updatePhone({
      variables: {
        phone: data.phone,
        updatePhoneId: phone.id,
      },
    });
  };

  const [updatePhone, { loading: mutationLoading }] = useMutation(
    UPDATE_PHONE,
    {
      onCompleted: () => {
        toast({
          title: "Phone updated",
          status: "success",
        });
      },
      onError(error) {
        error.graphQLErrors.forEach((error) => {
          switch (error.extensions.code) {
            case "PHONE_TAKEN":
              setError("phone", {
                type: "server",
                message: error.message,
              });
              break;
            default:
              toast({
                title: "Error occured",
                description: error.message,
                status: "error",
              });
          }
        });
      },
    }
  );

  return (
    <>
      <IconButton aria-label={""} size="sm" variant="ghost" onClick={onOpen}>
        <PencilSquareIcon className="h-4 w-4" />
      </IconButton>
      <Modal size="md" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Edit Phone</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <FormControl isInvalid={Boolean(errors.phone)}>
              <InputGroup>
                <InputLeftElement pointerEvents="none">
                  <RiPhoneLine />
                </InputLeftElement>
                <Input
                  placeholder="i.e 0433 701 042"
                  type="phone"
                  autoComplete="phone"
                  id="phone"
                  {...register("phone")}
                />
              </InputGroup>
              {!!errors.phone && (
                <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
              )}
            </FormControl>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>

            <Button
              colorScheme="blue"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
