import { CircularProgress, Flex } from "@chakra-ui/react";

import { ContactCollection } from "../../../types/Contact";
import { GET_INBOX_CONTACTS } from "../../../apollo/inbox";
import InboxList from "./inbox_list/InboxList";
import { useDebounce } from "use-debounce";
import { gql, useQuery, useReactiveVar } from "@apollo/client";
import { useEffect, useState } from "react";
import {
  isOpenVar,
  multiSelectVar,
  searchVar,
  sortOptionVar,
} from "../../../store/inbox";
import ToggleContacts from "./appbar/ToggleContacts";
import InboxAppBar from "./appbar/InboxAppBar";

type QueryData = {
  contacts: ContactCollection;
};

const INBOX_CONTACTS_SUBSCRIPTION = gql`
  subscription onInboxContactsUpdated {
    inboxContactUpdated {
      id
    }
  }
`;

function InboxView() {
  const sortOption = useReactiveVar(sortOptionVar);
  const search = useReactiveVar(searchVar);
  const isOpen = useReactiveVar(isOpenVar);

  const [debouncedValue] = useDebounce(search, 500);
  const { subscribeToMore, loading, data, fetchMore, refetch } =
    useQuery<QueryData>(GET_INBOX_CONTACTS, {
      variables: {
        filter: {
          hasInboxEvents: true,
          open: isOpen,
          search: debouncedValue,
        },
        sort: {
          field: sortOption.field,
          order: sortOption.value,
        },
        first: 20,
      },
      onCompleted(data) {},
      onError(error) {
        console.log(error);
      },
    });
  // Add a useEffect hook to refetch the query when isOpen changes
  useEffect(() => {
    // Refetch the query with updated isOpen value
    refetch({
      filter: {
        hasInboxEvents: true,
        open: isOpen,
        search: debouncedValue,
      },
    });
  }, [isOpen]);

  return (
    <div className="relative border-r max-w-lg w-full flex flex-col">
      <InboxAppBar />
      {loading ? (
        <div className="h-full flex place-items-center justify-center">
          <CircularProgress isIndeterminate color="blue.400" />
        </div>
      ) : (
        <InboxList
          data={data}
          fetchMore={fetchMore}
          subscribeToNewContactUpdates={subscribeToMore({
            document: INBOX_CONTACTS_SUBSCRIPTION,
            variables: { contactId: data?.contacts.edges[0]?.node.id },
            updateQuery: (prev, { subscriptionData }) => {
              if (!subscriptionData.data) return prev;
              const newFeedItem = subscriptionData;

              console.log(data?.contacts.edges[0]?.node.id)
              console.log("NFI", subscriptionData);

              return Object.assign({}, prev, {
                contacts: {
                  edges: [...prev.contacts.edges],
                },
              });
            },
          })}
        />
      )}
    </div>
  );
}

export default InboxView;
