import {
  Input,
  InputGroup,
  InputLeftElement,
  useMediaQuery,
} from "@chakra-ui/react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";

import { SCREENS } from "../../constants/SCREENS";
import SettingsNav from "./navigation/SettingsNav";
import { useEffect } from "react";
import Placeholder from "src/components/Placeholder";
import { Cog6ToothIcon, MagnifyingGlassIcon } from "@heroicons/react/24/solid";

export default function Index() {
  const location = useLocation();
  const isSettingsPage = Boolean(
    location.pathname.replaceAll("/", "") === "settings"
  );
  const [isDesktop] = useMediaQuery(SCREENS.DESKTOP);
  const navigate = useNavigate();
  useEffect(() => {
    if (location.pathname === "/settings" && isDesktop) {
      navigate("/settings/organisation");
    }
  }, []);

  return (
    <div className="h-full bg-white ">
      <div className="flex h-full ">
        {(location.pathname === "/settings" || isDesktop) && <SettingsNav />}
        {isSettingsPage ? (
          <div className="flex  mx-auto py-4 text-gray-400 text-sm h-full w-full  bg-gray-100 place-items-center">
            <div className="py-16 flex flex-col gap-2 place-items-center mx-auto">
              <Cog6ToothIcon className="w-10 h-10 text-gray-400" />
              <p>Select a settings page</p>
            </div>
          </div>
        ) : (
          <div className=" py-8 bg-gray-50 w-full">
            <div className="max-w-6xl mx-auto w-full">
              <Outlet />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
