import { Avatar } from "@chakra-ui/react";
import React from "react";
import { RiMailCloseLine } from "react-icons/ri";

type Props = {
  action: any;
  description: string;
  title: string;
  icon?: any;
};

export default function SettingsRow({
  icon,
  action,
  description,
  title,
}: Props) {
  return (
    <div className="grid md:grid-cols-5 gap-4 items-center">
      <div className="max-w-sm flex gap-4 place-items-start col-span-3">
        <div className="pt-1">{icon}</div>
        <div className="space-y-0.5">
          <h3 className="text-sm font-medium">{title}</h3>
          <p className="text-sm text-gray-500">{description}</p>
        </div>
      </div>
      <div className="col-span-2">{action}</div>
    </div>
  );
}
