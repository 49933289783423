import { Tooltip } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

export default function DesktopNavLink({ path, label, icon }: any) {
  return (
    <NavLink key={path} to={path}>
      {({ isActive }) => (
        <div
          className={`${
            isActive
              ? "bg-gray-100 border-gray-200 text-black"
              : "text-gray-800 border-white"
          } flex justify-between gap-3 px-3 py-[8px] rounded-md place-items-center  hover:bg-gray-100 hover:text-black border font-medium text-sm transition-300 hover:border-gray-200 transition ease`}
        >
          <div className="flex gap-3 place-items-center py-0.5">
            {isActive ? icon : icon}
            {label}
          </div>

          {/*       <div className="bg-gray-100 border border-gray-200 px-2 py-0.5 rounded-full text-xs font-medium">
            12
          </div> */}
        </div>
      )}
    </NavLink>
  );
}
