import {
  Input,
  FormControl,
  FormErrorMessage,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  useDisclosure,
  Button,
  InputGroup,
  InputLeftElement,
  useToast,
} from "@chakra-ui/react";
import * as yup from "yup";

import { RiPhoneLine } from "react-icons/ri";
import { useMutation } from "@apollo/client";
import phone from "phone";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import { GET_USER_DETAILS } from "src/apollo/user";
import { CREATE_PHONE } from "src/apollo/phone";
const schema = yup.object().shape({
  phone: yup
    .string()
    .required("Please enter a phone phone")
    .test("isPhoneValid", "Invalid phone phone", (value) =>
      value != undefined && value.length
        ? phone(value, { country: "AU" }).isValid
        : true
    ),
});

type FormData = {
  phone: any;
};

export default function CreatePhone() {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const toast = useToast();
  const {
    register,
    handleSubmit,
    setValue,
    setError,
    formState: { errors, isDirty },
  } = useForm({
    resolver: yupResolver(schema),
    defaultValues: {
      phone: "",
    },
  });

  const [createPhone, { loading: mutationLoading }] = useMutation(
    CREATE_PHONE,
    {
      refetchQueries: [{ query: GET_USER_DETAILS }, "GetUserDetails"],
      onCompleted: () => {
        toast({
          title: "Phone connected",
          status: "success",
        });
      },
      onError(error) {
        error.graphQLErrors.forEach((error) => {
          switch (error.extensions.code) {
            case "PHONE_TAKEN":
              setError("phone", {
                type: "server",
                message: error.message,
              });
              break;
            default:
              toast({
                title: "Error occured",
                description: error.message,
                status: "error",
              });
          }
        });
      },
    }
  );
  const onSubmit = (data: FormData) => {
    createPhone({
      variables: {
        name: "",
        phone: phone(data.phone, { country: "AU" }).phoneNumber,
      },
    });
  };

  return (
    <>
      <Button variant="outline" size="sm" onClick={onOpen}>
        Connect
      </Button>
      <Modal size="md" isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Add Phone</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <form>
              <FormControl isInvalid={Boolean(errors.phone)}>
                <InputGroup>
                  <InputLeftElement pointerEvents="none">
                    <RiPhoneLine />
                  </InputLeftElement>
                  <Input
                    placeholder="i.e 0433 701 042"
                    type="phone"
                    autoComplete="phone"
                    id="phone"
                    {...register("phone")}
                  />
                </InputGroup>
                {!!errors.phone && (
                  <FormErrorMessage>{errors.phone.message}</FormErrorMessage>
                )}
              </FormControl>
            </form>
          </ModalBody>
          <ModalFooter>
            <Button mr={3} onClick={onClose}>
              Close
            </Button>
            <Button
              colorScheme="blue"
              type="button"
              onClick={handleSubmit(onSubmit)}
            >
              Save
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
}
