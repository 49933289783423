import { gql } from "@apollo/client";

export const CREATE_PHONE = gql`
  mutation CreatePhone($phone: String, $name: String) {
    createPhone(phone: $phone, name: $name) {
      phone
      name
      twilioUri
    }
  }
`;

export const DELETE_PHONE = gql`
  mutation DeletePhone($deletePhoneId: ID!) {
    deletePhone(id: $deletePhoneId) {
      phone
    }
  }
`;

export const UPDATE_PHONE = gql`
  mutation Mutation($updatePhoneId: ID!, $phone: String) {
    updatePhone(id: $updatePhoneId, phone: $phone) {
      id
      phone
    }
  }
`;
