import { useReactiveVar } from "@apollo/client";
import {
  Button,
  IconButton,
  Menu,
  MenuButton,
  MenuItemOption,
  MenuList,
  MenuOptionGroup,
} from "@chakra-ui/react";
import React from "react";
import { RiFilter3Fill, RiFilterLine } from "react-icons/ri";
import { isOpenVar, multiSelectVar } from "src/store/inbox";

export default function InboxSelect() {
  const isOpen = useReactiveVar(isOpenVar);
  const multiSelect = useReactiveVar(multiSelectVar);
  const handleSelect = (value: string) => {
    multiSelectVar({ ...multiSelect, selectedContacts: [] });
    isOpenVar(value === "true");
  };
  return (
    <Menu>
      <MenuButton variant="ghost" as={Button} rightIcon={<RiFilter3Fill />}>
        {isOpen ? "Open" : "Closed"} Conversations
      </MenuButton>
      <MenuList>
        <MenuOptionGroup
          title="Select Inbox:"
          type="radio"
          value={isOpen.toString()}
          onChange={(value: any) => {
            handleSelect(value);
          }}
        >
          <MenuItemOption key="Open" value={"true"}>
            Open Conversations
          </MenuItemOption>
          <MenuItemOption key="Close" value={"false"}>
            Closed Conversations
          </MenuItemOption>
        </MenuOptionGroup>
      </MenuList>
    </Menu>
  );
}
