import type { StyleFunctionProps } from "@chakra-ui/styled-system";
import { extendTheme } from "@chakra-ui/react";

const theme = extendTheme({
  components: {
    Card: {
      baseStyle: {
        boxShadow: "none",
        borderColor: "gray",
        header: {
          paddingBottom: 0,
        },
      },
    },
    Input: {
      baseStyle: {
        // Customize input styles here
        boxShadow: "0px 2px 1px 0px rgba(149, 160, 178, 0.08);",
        height: "40px",
      },
    },
    FormLabel: {
      baseStyle: {
        // Customize label styles here

        fontSize: "14px", // Change the label font size
        fontWeight: "medium", // Change the label font weight
      },
    },
    Button: {
      baseStyle: {
        boxShadow: "0px 2px 1px 0px rgba(149, 160, 178, 0.08)", // Apply custom shadow to all buttons
      },
    },
    Popover: {
      baseStyle: {
        body: {
          paddingTop: 0,
        },
        header: {
          borderBottomWidth: "0",

          fontWeight: "bold", // Remove the border
        },
        footer: {
          borderTopWidth: "0",
        },
      },
    },
  },
});

export default theme;
