import { IconButton } from "@chakra-ui/react";
import { PaperAirplaneIcon } from "@heroicons/react/24/solid";
import { inputStatesVar } from "../../../../../store/messaging";
import useEmail from "../../hooks/useEmail";
import { useReactiveVar } from "@apollo/client";
import useReview from "../../hooks/useReview";
import useSMS from "../../hooks/useSMS";
import useFacebookChat from "../../hooks/useFacebookChat";
import useFacebookRecommendation from "../../hooks/useFacebookRecommendation";
import useWebchat from "../../hooks/useWebchat";
import { useEffect } from "react";
import { useSettings } from "src/hooks/useSettings";

export default function SendButton({ contacts, contact, channels }: any) {
  const inputStates = useReactiveVar(inputStatesVar);
  const { settings } = useSettings();
  const { handleSubmitSMS, loading, error, data } = useSMS(contact, contacts);

  const { handleSubmitEmail, emailLoading, emailError, emailData } = useEmail(
    contact,
    contacts
  );

  const { handleSubmitReview, reviewLoading, reviewError } = useReview(
    contact ? contact.id! : ""
  );

  const { handleSubmitFacebookChat, facebookChatLoading, facebookChatError } =
    useFacebookChat(contact, contacts);

  const { handleSubmitWebchat, webchatLoading, webchatError } = useWebchat(
    contact,
    contacts
  );

  const {
    handleSubmitFacebookRecommendation,
    facebookRecommendationLoading,
    facebookRecommendationError,
  } = useFacebookRecommendation(contact, contacts);
  function sendMessage() {
    switch (inputStates.selectedChannel) {
      case "EMAIL":
        handleSubmitEmail();
        break;
      case "REVIEW":
        handleSubmitReview();
        break;
      case "SMS":
        handleSubmitSMS();
        break;
      case "FACEBOOK_CHAT":
        handleSubmitFacebookChat();
        break;
      case "FACEBOOK_RECOMMENDATION":
        handleSubmitFacebookRecommendation();
        break;
      case "WEBCHAT":
        handleSubmitWebchat();
        break;
    }
  }

  const isFocused = inputStates.inputFocus;

  useEffect(() => {
    if (
      isFocused &&
      ![
        "iPhone",
        "iPad",
        "iPod",
        "Android",
        "BlackBerry",
        "Windows Phone",
      ].includes(navigator.platform)
    ) {
      const handleKeyPress = (event: KeyboardEvent) => {
        if (settings.enterToSend) {
          if (event.key === "Enter" && event.shiftKey === false) {
            event.preventDefault();
            sendMessage();
          }
        }
      };

      window.addEventListener("keydown", handleKeyPress);

      return () => {
        window.removeEventListener("keydown", handleKeyPress);
      };
    }
  }, [isFocused]);

  return (
    <IconButton
      variant="solid"
      colorScheme="blue"
      isLoading={
        emailLoading ||
        loading ||
        reviewLoading ||
        webchatLoading ||
        facebookChatLoading ||
        facebookRecommendationLoading
      }
      aria-label="Send Message"
      onClick={() => {
        sendMessage();
      }}
    >
      <PaperAirplaneIcon className="w-6 h-6" />
    </IconButton>
  );
}
