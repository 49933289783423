import ContactsAppBar from "./appbar/ContactsAppBar";
import ContactsTable from "./table/ContactsTable";
import { FILTER_OPTIONS } from "../../../constants/FILTER_OPTIONS";
import { SORT_OPTIONS } from "../../../constants/SORT_OPTIONS";
import { useState } from "react";

function ContactsView() {
  const [sortOption, setSortOption] = useState(SORT_OPTIONS[0]);
  const [filterOption, setFilterOption] = useState(FILTER_OPTIONS[0]);
  const [search, setSearch] = useState("");

  return (
    <div className="flex flex-col md:border-r h-full  w-full h-full">
      <ContactsAppBar
        sortOption={sortOption}
        setSortOption={setSortOption}
        filterOption={filterOption}
        setFilterOption={setFilterOption}
        search={search}
        setSearch={setSearch}
      />

      <ContactsTable />
    </div>
  );
}

export default ContactsView;
