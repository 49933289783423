import { CHANNELS } from "./CHANNELS";

export const FILTER_OPTIONS = [
  {
    name: "All Channels",
    channel: "all",
    icon: <></>,
  },
  {
    name: "Phone",
    channel: "phone",
    icon: CHANNELS["SMS"].iconSmall,
  },
  {
    name: "Email",
    channel: "email",
    icon: CHANNELS["EMAIL"].iconSmall,
  },
  {
    name: "Google Review",
    channel: "googleDisplayName",
    icon: CHANNELS["REVIEW"].iconSmall,
  },
  {
    name: "Facebook",
    channel: "facebook",
    icon: CHANNELS["FACEBOOK_CHAT"].iconSmall,
  },
  {
    name: "Feedback",
    channel: "feedback",
    icon: CHANNELS["FEEDBACK"].iconSmall,
  },
  {
    name: "Webchat",
    channel: "webchat",
    icon: CHANNELS["WEBCHAT"].iconSmall,
  },
];
