import React from "react";
import DeletePhone from "./DeletePhone";
import UpdatePhone from "./UpdatePhone";
import CreatePhone from "./CreatePhone";

type Props = {
  phones: any;
};

export default function Phone({ phones }: Props) {
  return (
    <div className="space-y-2 mb-2">
      {phones.map((phone: any) => {
        return (
          <div key={phone.id} className="flex gap-2 justify-between">
            <p>{phone.phone}</p>
            <div className="gap-1 flex">
              <UpdatePhone phone={phone} />
              <DeletePhone phoneId={phone.id} />
            </div>
          </div>
        );
      })}
      <CreatePhone />
    </div>
  );
}
