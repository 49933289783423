import {
  GET_CONTACT_INBOX_EVENTS,
  SEND_WEBCHAT_CHAT,
  SEND_TEXT_MESSAGE,
} from "../../../../apollo/messaging";
import {
  attachmentStateVar,
  contactStatesVar,
  inputStatesVar,
  isMessageSendingVar,
  mediaUrlsVar,
} from "../../../../store/messaging";
import {
  useApolloClient,
  useMutation,
  useQuery,
  useReactiveVar,
} from "@apollo/client";

import { Contact } from "../../../../types/Contact";
import { useToast } from "@chakra-ui/react";
import { useEffect, useRef } from "react";

export default function useWebchat(
  contact: Contact | undefined,
  contactIds: Contact[] | undefined
) {
  const contactStates = useReactiveVar(contactStatesVar);
  const inputStates = useReactiveVar(inputStatesVar);
  const attachmentState = useReactiveVar(attachmentStateVar);
  const webchatRef = useRef(inputStates.webchat);
  const toast = useToast();
  const [
    sendWebchat,
    {
      data: webchatData,
      loading: webchatLoading,
      error: webchatError,
    },
  ] = useMutation(SEND_WEBCHAT_CHAT, {
    refetchQueries: () => {
      if (contact)
        return [
          {
            query: GET_CONTACT_INBOX_EVENTS,
            variables: {
              contactId: contact.id,
            },
          },
          "GetContactInboxEvents",
        ];
      return [];
    },
    onError(error) {
      isMessageSendingVar(false);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
    onCompleted(data) {
      if (contact) {
        contactStatesVar({
          ...contactStates,
          [contact.id]: {
            ...contactStates[contact.id],
            webchat: "",
          },
        });
      }
      isMessageSendingVar(false);
      attachmentStateVar(null);
    },
  });

  useEffect(() => {
    webchatRef.current = inputStates.webchat;
  }, [inputStates.webchat]);

  const getMessageObject = () => {
    if (attachmentState) {
      return {
        chat: webchatRef.current,
        contactId: [contact!.id],
        attachments: [attachmentState],
      };
    }

    return {
      chat: webchatRef.current,
      contactId: [contact!.id],
    };
  };

  function handleSubmitWebchat() {
    if (!webchatRef.current && !attachmentState) return;
    isMessageSendingVar(true);

    sendWebchat({
      variables: {
        message: getMessageObject(),
      },
    });
  }

  return {
    handleSubmitWebchat,
    webchatLoading,
    webchatError,
    webchatData,
  };
}
