import { IconButton, useToast } from "@chakra-ui/react";
import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/24/outline";
import { DELETE_PHONE } from "src/apollo/phone";

type Props = {
  phoneId: string;
};
export default function DeletePhone({ phoneId }: Props) {
  const toast = useToast();
  const [deletePhone, { data, loading: isLoading, error }] = useMutation(
    DELETE_PHONE,
    {
      refetchQueries: ["GetUserDetails"],
      onCompleted: (data) => {
        toast({
          title: "Phone Deleted",
          status: "success",
        });
      },
      onError(error) {
        toast({
          title: "Error occured",
          description: error.message,
          status: "error",
        });
      },
    }
  );
  function handleDelete(id: string) {
    deletePhone({
      variables: {
        deletePhoneId: id,
      },
    });
  }
  return (
    <IconButton
      aria-label="Delete Phone Phone"
      size="sm"
      variant="ghost"
      onClick={() => handleDelete(phoneId)}
    >
      <TrashIcon className="h-4 w-4" />
    </IconButton>
  );
}
