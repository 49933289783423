import { useMutation } from "@apollo/client";
import { Button, useToast } from "@chakra-ui/react";
import { RiFacebookLine } from "react-icons/ri";
import { DISCONNECT_FACEBOOK_PAGE } from "src/apollo/organisation";
import { GET_USER_DETAILS } from "src/apollo/user";

type Props = {
  facebookAuthorisationLink: any;
  facebookPages: any[];
  refetchPages: () => void;
};

export default function FacebookAuthentication({
  facebookAuthorisationLink,
  facebookPages,
  refetchPages,
}: Props) {
  const toast = useToast();
  const [
    disconnectFacebookPage,
    {
      data: facebookPageData,
      loading: facebookPageLoading,
      error: facebookPageError,
    },
  ] = useMutation(DISCONNECT_FACEBOOK_PAGE, {
    refetchQueries: [{ query: GET_USER_DETAILS }, "GetUserDetails"],
    onError(error) {
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
    onCompleted(data) {
      toast({
        title: "Page disconnected",
        status: "success",
      });
    },
  });

  if (facebookAuthorisationLink && !facebookPages.length) {
    return (
      <Button
        as="a"
        size="sm"
        href={facebookAuthorisationLink.url}
        variant={"outline"}
      >
        Connect
      </Button>
    );
  } else {
    return (
      <div className="flex flex-col justify-center gap-2">
        {facebookPages?.map((page) => {
          return (
            <div
              key={page.id}
              className="flex justify-between items-center gap-2"
            >
              <Button
                as="a"
                size="sm"
                variant="outline"
                className="flex w-full items-center ml-0 justify-start gap-2 "
                target="_blank"
                href={`https://facebook.com/profile.php?id=${page.pageId}`}
              >
                <img
                  src={page.pagePicture}
                  alt={page.pageName}
                  className="rounded-full w-6 h-6"
                />

                <p className="text-sm text-gray-500">{page.pageName}</p>
              </Button>
              {page.expiredAt ? (
                <Button
                  as="a"
                  href={facebookAuthorisationLink.url}
                  size="sm"
                  variant="outline"
                >
                  Reconnect
                </Button>
              ) : (
                <form
                  onSubmit={(e) => {
                    e.preventDefault();
                    disconnectFacebookPage({
                      variables: {
                        pageId: page.pageId,
                      },
                    });
                  }}
                >
                  <Button type="submit" size="sm" variant="outline">
                    Disconnect
                  </Button>
                </form>
              )}
            </div>
          );
        })}
      </div>
    );
  }
}
