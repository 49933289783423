import {
  IconButton,
  Menu,
  MenuButton,
  MenuGroup,
  MenuItem,
  MenuList,
  Text,
  useToast,
} from "@chakra-ui/react";
import {
  contactStatesVar,
  inputStatesVar,
} from "../../../../../store/messaging";
import { useQuery, useReactiveVar } from "@apollo/client";

import { ArrowRightIcon } from "@heroicons/react/24/outline";
import { Contact } from "../../../../../types/Contact";
import { GET_GOOGLE_CREDENTIALS } from "../../../../../apollo/organisation";
import { GET_TEMPLATES_AND_KEYWORDS } from "../../../../../apollo/templates";
import { GET_USER_DETAILS } from "../../../../../apollo/user";
import { Keyword } from "../../../../../types/Keyword";
import { RiChat1Line } from "react-icons/ri";
import { Template } from "../../../../../types/Template";
import { useCurrentEditor } from "@tiptap/react";
import { useNavigate } from "react-router-dom";

type Props = {
  contact: Contact | undefined;
  editor: any;
};

export default function TemplatesSelect({ contact, editor }: Props) {
  const navigate = useNavigate();
  const toast = useToast();
  const inputStates = useReactiveVar(inputStatesVar);
  const contactStates = useReactiveVar(contactStatesVar);
  const { data: g_data } = useQuery(GET_GOOGLE_CREDENTIALS, {
    onError(error) {
      console.log(error);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
  });

  const { data: userData } = useQuery(GET_USER_DETAILS, {
    onError(error) {
      console.log(error);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
  });

  const { data } = useQuery(GET_TEMPLATES_AND_KEYWORDS, {
    onError(error) {
      console.log(error);
      toast({
        title: "Error occured",
        description: error.message,
        status: "error",
      });
    },
  });

  function getReviewURL() {
    if (!g_data.googleCredential) return;

    const metaInformation = g_data.googleCredential.locations.reduce(
      (prevLocation: any, currLocation: any): any => {
        if (
          Number(prevLocation.totalReviewCount) >
          Number(currLocation.totalReviewCount)
        ) {
          return prevLocation;
        }
        return currLocation;
      }
    );
    return metaInformation;
  }

  function updateInputState(formattedString: string) {
    if (!contact) return null;

    switch (contactStates[contact.id].selectedChannel) {
      case "SMS":
        inputStatesVar({
          ...inputStates,
          SMSBody: formattedString,
        });
        contactStatesVar({
          ...contactStates,
          [contact.id]: {
            ...contactStates[contact.id],
            SMSBody: formattedString,
          },
        });
        editor?.commands.insertContent(formattedString);
        break;
      case "EMAIL":
        contactStatesVar({
          ...contactStates,
          [contact.id]: {
            ...contactStates[contact.id],
            emailBody: formattedString,
          },
        });
        inputStatesVar({
          ...inputStates,
          emailBody: formattedString,
        });
        editor?.commands.insertContent(formattedString);

        break;
      case "REVIEW":
        contactStatesVar({
          ...contactStates,
          [contact.id]: {
            ...contactStates[contact.id],
            reviewReply: formattedString,
          },
        });
        inputStatesVar({
          ...inputStates,
          reviewReply: formattedString,
        });
        editor?.commands.insertContent(formattedString);
        break;
      case "FACEBOOK_CHAT":
        contactStatesVar({
          ...contactStates,
          [contact.id]: {
            ...contactStates[contact.id],
            facebookChat: formattedString,
          },
        });
        inputStatesVar({
          ...inputStates,
          facebookChat: formattedString,
        });
        editor?.commands.insertContent(formattedString);
        break;
      case "WEBCHAT":
        contactStatesVar({
          ...contactStates,
          [contact.id]: {
            ...contactStates[contact.id],
            webchat: formattedString,
          },
        });
        inputStatesVar({
          ...inputStates,
          webchat: formattedString,
        });
        editor?.commands.insertContent(formattedString);
        break;
    }
  }

  function formatString(matchedValues: string[], body: string) {
    let formattedString = body;
    matchedValues.forEach((matchedValue) => {
      const formattedValue = matchedValue
        .replace(/[{}]/g, "")
        .trim()
        .toLowerCase();

      data.keywords.forEach((keyword: Keyword) => {
        const lowerCaseKey = keyword.key.toLowerCase();
        if (lowerCaseKey === formattedValue)
          formattedString = formattedString.replaceAll(
            matchedValue,
            keyword.value
          );
      });

      if (contact && formattedValue.includes("contact.name") && contact.name) {
        formattedString = formattedString.replaceAll(
          matchedValue,
          contact.name.split(" ")[0]
        );
      }
      if (formattedValue.includes("user.name") && userData.me) {
        formattedString = formattedString.replaceAll(
          matchedValue,
          userData.me.name
        );
      }
      if (formattedValue.includes("google.review")) {
        const metaInformation = getReviewURL();
        formattedString = formattedString.replaceAll(
          matchedValue,
          metaInformation
            ? metaInformation.newReviewUrl
            : "Google not connected"
        );
      }
    });
    return formattedString;
  }

  function handleTemplateClick(template: Template) {
    const matchedValues = [...template.body.matchAll(/{(.*?)}/g)].map(
      (matchedObject) => {
        return matchedObject[0];
      }
    );
    const formattedString = formatString(matchedValues, template.body);
    updateInputState(formattedString);
  }

  if (!inputStates.selectedChannel) return null;
  return (
    <Menu>
      <MenuButton as={IconButton} aria-label="Select Template" variant="ghost">
        <RiChat1Line className="mx-auto" />
      </MenuButton>

      <MenuList>
        {data && data.templates.length > 0 ? (
          data.templates.map((template: Template) => {
            return (
              <MenuItem
                key={template.id}
                onClick={() => handleTemplateClick(template)}
              >
                {template.name}
              </MenuItem>
            );
          })
        ) : (
          <MenuGroup title="No templates created">
            <MenuItem
              className="flex justify-between place-items-center"
              onClick={() => navigate("/settings/templates")}
            >
              <Text>Create a template</Text>
              <ArrowRightIcon className="w-4 h-4"></ArrowRightIcon>
            </MenuItem>
          </MenuGroup>
        )}
      </MenuList>
    </Menu>
  );
}
