import { GET_USER_DETAILS } from "../apollo/user";
import { NAVLINKS } from "../constants/NAVLINKS";
import { useQuery } from "@apollo/client";
import DesktopNavLink from "./DesktopNavLink";
import DesktopNavCompany from "./DesktopNavCompany";
import DesktopNavUser from "./DesktopNavUser";

export default function DesktopNavBar() {
  const { data } = useQuery(GET_USER_DETAILS, {
    onError(error) {
      console.log(error);
    },
  });

  return (
    <nav className="flex flex-col justify-between flex-shrink-0 h-full w-64 border-r ">
      <div>
        <DesktopNavCompany name={data.me.organisation.name} />
        <div className="bg-gray-200 h-px w-full"></div>
        <div className="flex flex-col space-y-1 p-2">
          {NAVLINKS.map((NAVLINK) => {
            return (
              <DesktopNavLink
                icon={NAVLINK.icon}
                label={NAVLINK.label}
                path={NAVLINK.path}
                key={NAVLINK.path}
              />
            );
          })}
        </div>
      </div>
      <div className="flex flex-col gap-2 ">
        <div className="bg-gray-200 h-px w-full"></div>
        <DesktopNavUser name={data.me.name} role={data.me.role} />
      </div>
    </nav>
  );
}
