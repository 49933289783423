import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Input,
  Step,
  StepDescription,
  StepIcon,
  StepIndicator,
  StepNumber,
  StepSeparator,
  StepStatus,
  StepTitle,
  Stepper,
  useSteps,
} from "@chakra-ui/react";
import SettingsLayout from "../SettingsLayout";
import { useEffect, useRef, useState } from "react";
import { ScriptCopy } from "./ScriptCopy";
import TimeSelector from "./TimeSelector";

export default function WebchatEmbedPage() {
  const { activeStep, setActiveStep } = useSteps({
    index: 0,
    count: 4,
  });
  const [textColor, setTextColor] = useState("#FFFFFF");
  const [openedDays, setOpenedDays] = useState<string[]>([]);
  const [openAt, setOpenAt] = useState("09:00");
  const [closedAt, setClosedAt] = useState("17:00");
  const [selectedColor, setSelectedColor] = useState("#308FFF");
  const colorRef = useRef<HTMLInputElement>(null);

  const completeStep = (index: number) => {
    setActiveStep(index + 1);

    const step = document.getElementById(`step-${index.toString()}`);

    if (step) {
      setTimeout(() => {
        step.scrollIntoView({ behavior: "smooth" });
      }, 100);
    }
  };

  let days = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    function getTextColorBasedOnBackground(hex: string) {
      hex = hex.replace("#", "");

      let r = parseInt(hex.substring(0, 2), 16);
      let g = parseInt(hex.substring(2, 4), 16);
      let b = parseInt(hex.substring(4, 6), 16);

      let yiq = (r * 299 + g * 587 + b * 114) / 1000;

      return yiq >= 128 ? "#000000" : "#FFFFFF";
    }

    setTextColor(getTextColorBasedOnBackground(selectedColor));
  }, [selectedColor]);

  const steps = [
    {
      title: "Select color",
      description: "Choose how your webchat will look",
      card: (
        <div>
          <input
            ref={colorRef}
            type="color"
            value={selectedColor}
            onChange={(e) => {
              setSelectedColor(e.target.value);
              completeStep(0);
            }}
            hidden
          />
          <button
            style={{ backgroundColor: selectedColor, color: textColor }}
            className={`w-full h-12 rounded-xl border-gray-200 border font-semibold`}
            onClick={() => colorRef.current!.click()}
          >
            {selectedColor}
          </button>
        </div>
      ),
    },
    {
      title: "What times will your webchat be available?",
      description: "Choose when your webchat will show that you're available",
      card: (
        <div className="flex flex-col gap-4">
          <div className="flex gap-4">
            <FormControl>
              <FormLabel>Opens</FormLabel>
              <Input
                type="time"
                value={openAt}
                onChange={(e) => setOpenAt(e.target.value)}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Closes</FormLabel>
              <Input
                type="time"
                value={closedAt}
                onChange={(e) => setClosedAt(e.target.value)}
              />
            </FormControl>
          </div>
          <div className="flex gap-4">
            {days.map((day) => (
              <Button
                key={day}
                variant={openedDays.includes(day) ? "outline" : "solid"}
                onClick={() => {
                  if (openedDays.includes(day)) {
                    setOpenedDays(openedDays.filter((d) => d !== day));
                  } else {
                    setOpenedDays((prevDays) => [...prevDays, day]);
                  }
                }}
                size="sm"
                style={{
                  flexGrow: 1,
                }}
              >
                {day}
              </Button>
            ))}
          </div>
          <Button
            variant="outline"
            size="sm"
            onClick={() => {
              console.log(openAt, closedAt);
              completeStep(1);
            }}
            isDisabled={!openedDays.length}
          >
            Continue
          </Button>
        </div>
      ),
    },
    {
      title: "Add scripts to website head",
      description:
        "Add this script to your website head, and you're good to go",
      card: (
        <ScriptCopy
          completeStep={completeStep}
          color={selectedColor}
          openAt={openAt}
          closedAt={closedAt}
          openedDays={openedDays}
        />
      ),
    },
  ];

  return (
    <SettingsLayout
      name="Webchat Embed"
      description="Add a webchat to your website"
    >
      <Stepper
        index={activeStep}
        orientation="vertical"
        gap="0"
        size={"lg"}
        colorScheme="green"
        width={"100%"}
        style={{
          display: "flex",
        }}
      >
        {steps
          .filter((step, index) => index <= activeStep)
          .map((step, index) => (
            <Step key={index} className="flex-1">
              <StepIndicator>
                <StepStatus
                  complete={<StepIcon />}
                  incomplete={<StepNumber />}
                  active={<StepNumber />}
                />
              </StepIndicator>
              <Box ml={6} width={"100%"}>
                <Flex justifyContent="space-between" gap={12}>
                  <Box mb={4} maxWidth={700}>
                    <StepTitle>{step.title}</StepTitle>
                    <StepDescription>{step.description}</StepDescription>
                  </Box>
                </Flex>
                <div id={`step-${index}`}>
                  <Box mb={16}>{step.card}</Box>
                </div>
              </Box>
              <StepSeparator />
            </Step>
          ))}
      </Stepper>
    </SettingsLayout>
  );
}
