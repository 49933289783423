import { useQuery } from "@apollo/client";
import FacebookAuthentication from "./FacebookAuthentication";
import SettingsLayout from "../SettingsLayout";
import SettingsRow from "../SettingsRow";
import { GET_ORGANISATION } from "src/apollo/organisation";
import GoogleAuthentication from "./GoogleAuthentication";
import { Center, CircularProgress } from "@chakra-ui/react";
import EmailAuthentication from "./EmailAuthentication";
import Phone from "../organisation/phone/Phone";

export default function IntegrationsView() {
  const { data, loading, error, refetch } = useQuery(GET_ORGANISATION);
  console.log(data);
  if (loading) {
    return (
      <Center w="100%">
        <CircularProgress isIndeterminate color="blue.400" />
      </Center>
    );
  }

  if (error) {
    return null;
  }

  return (
    <SettingsLayout
      name="Integrations"
      description="Connect external services to Smartyr"
    >
      <SettingsRow
        title="Email"
        description="Connect an existing email or create a new one"
        action={
          <EmailAuthentication emails={data?.emails} refetchEmails={refetch} />
        }
      />
      <SettingsRow
        title="Google"
        description="Authorise to access reviews"
        action={
          <GoogleAuthentication
            googleCredential={data?.googleCredential}
            googleAuthorisationLink={data?.googleAuthorisationLink}
          />
        }
      />
      <SettingsRow
        title="Facebook"
        description="Authorise to access messages and reviews"
        action={
          <FacebookAuthentication
            facebookAuthorisationLink={data?.facebookAuthorisationLink}
            facebookPages={data?.facebookPages}
            refetchPages={refetch}
          />
        }
      />
      <SettingsRow
        title="SMS"
        description="Create a phone number to send and receive text messages"
        action={<Phone phones={data.me.organisation.phones} />}
      />
    </SettingsLayout>
  );
}
