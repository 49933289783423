import {
  BuildingOfficeIcon,
  ChatBubbleBottomCenterTextIcon,
  StarIcon,
  UserCircleIcon,
  UserGroupIcon,
  AdjustmentsHorizontalIcon,
  SquaresPlusIcon,
  ChatBubbleLeftRightIcon,
} from "@heroicons/react/24/outline";
import { IconButton, useMediaQuery } from "@chakra-ui/react";
import { NavLink } from "react-router-dom";

import { useAuth } from "src/hooks/useAuth";
import { ArrowRightOnRectangleIcon } from "@heroicons/react/24/solid";
import { SCREENS } from "src/constants/SCREENS";
import LogoutButton from "../LogoutButton";
import SettingsNavLink from "./SettingsNavLink";

export default function SettingsNav() {
  const { logout } = useAuth();
  const [isDesktop] = useMediaQuery(SCREENS.DESKTOP);
  const SETTINGS_NAVLINKS = [
    {
      name: "Profile",
      path: "user",
      icon: <UserCircleIcon className="flex-shrink-0 w-5 h-5" />,
    },
    {
      name: "Integrations",
      path: "integrations",
      icon: <SquaresPlusIcon className="w-5 h-5" />,
    },
    {
      name: "Organisation",
      path: "organisation",
      icon: <BuildingOfficeIcon className="w-5 h-5" />,
    },
    /*     {
      name: "Users",
      path: "/settings/users",
      icon: <UserGroupIcon className="w-5 h-5" />,
    }, */
    {
      name: "Templates",
      path: "templates",
      icon: <ChatBubbleBottomCenterTextIcon className="w-5 h-5" />,
    },

    {
      name: "Application",
      path: "application",
      icon: <AdjustmentsHorizontalIcon className="w-5 h-5" />,
    },
    {
      name: "Review Embed",
      path: "review-embed",

      icon: <StarIcon className="flex-shrink-0 w-5 h-5" />,
    },
    {
      name: "Webchat Embed",
      path: "webchat-embed",

      icon: <ChatBubbleLeftRightIcon className="flex-shrink-0 w-5 h-5" />,
    },
  ];

  return (
    <div className=" flex-shrink-0 w-full bg-white  w-[300px] h-full border-r border-gray-200  md:pt-7 md:p-3 flex flex-col">
      <div className=" px-2 md:pt-0 pb-3 border-b mb-2 pt-3">
        <h2 className="md:text-gray-500 text-lg  md:text-xs font-medium md:uppercase md:tracking-wider">
          settings
        </h2>
      </div>

      <div className="flex flex-col h-full p-2 md:p-0 h-full justify-between">
        <div className="flex flex-col space-y-1">
          {SETTINGS_NAVLINKS.map((navLink) => {
            return (
              <SettingsNavLink
                path={navLink.path}
                icon={navLink.icon}
                name={navLink.name}
              />
            );
          })}
        </div>
        <LogoutButton />
      </div>
    </div>
  );
}
