import { Button } from "@chakra-ui/button";
import { GoogleCredential } from "../../../types/Organisation";
import { RiGoogleLine } from "react-icons/ri";
import { CheckCircleIcon, CheckIcon } from "@heroicons/react/24/outline";
import { gql, useMutation } from "@apollo/client";
import { GET_ORGANISATION } from "src/apollo/organisation";

type Props = {
  googleAuthorisationLink: any;
  googleCredential: GoogleCredential;
};
const REVOKE_GOOGLE_TOKEN = gql`
  mutation RevokeGoogleToken {
    revokeGoogleToken {
      success
      message
    }
  }
`;
export default function GoogleAuthentication({
  googleAuthorisationLink,
  googleCredential,
}: Props) {
  const [revokeGoogleToken, { data, loading, error }] = useMutation(
    REVOKE_GOOGLE_TOKEN,
    {
      onError(error) {
        console.log(error);
      },
      onCompleted(data) {
        console.log(data);
      },
      refetchQueries: [
        {
          query: GET_ORGANISATION,
        },
        "GetOrganisation",
      ],
    }
  );

  if (
    (!googleCredential && googleAuthorisationLink) ||
    (googleCredential && googleCredential.expiredAt)
  )
    return (
      <Button
        disabled={loading}
        as="a"
        size="sm"
        href={googleAuthorisationLink.url}
        variant={"outline"}
        leftIcon={<RiGoogleLine />}
      >
        Connect to Google
      </Button>
    );
  else {
    return (
      <Button
        onClick={() => revokeGoogleToken()}
        variant="outline"
        colorScheme="red"
        size="sm"
      >
        Remove permissions
      </Button>
    );
  }
}
