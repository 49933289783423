import {
  Button,
  ButtonGroup,
  Flex,
  IconButton,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverFooter,
  PopoverHeader,
  PopoverTrigger,
  Portal,
  Text,
  Tr,
  useToast,
} from "@chakra-ui/react";
import {
  DELETE_TEMPLATE,
  GET_TEMPLATES_AND_KEYWORDS,
} from "../../../apollo/templates";
import { Keyword } from "../../../types/Keyword";
import { Template } from "../../../types/Template";
import TemplateModal from "./TemplateModal";
import { useMutation } from "@apollo/client";
import { TrashIcon } from "@heroicons/react/24/solid";

type Props = {
  template: Template;

  keywords: Keyword[];
};

export default function TemplateItem({ template, keywords }: Props) {
  const toast = useToast();
  const [deleteTemplate, { loading: deleteTemplateLoading }] = useMutation(
    DELETE_TEMPLATE,
    {
      refetchQueries: [
        { query: GET_TEMPLATES_AND_KEYWORDS },
        "GetTemplatesAndKeywords",
      ],
      onCompleted: (data) => {},
      onError(error) {
        console.log(error);
        toast({
          title: "Error occured",
          description: error.message,
          status: "error",
        });
      },
    }
  );

  return (
    <div key={template.id} className="flex gap-4">
      <div className="flex flex-col gap-1 w-full">
        <div className="font-medium">{template.name}</div>

        <p className="line-clamp-2 text-sm text-gray-600">{template.body} </p>
      </div>
      <ButtonGroup gap="1px">
        <TemplateModal template={template} keywords={keywords} />
        <Popover>
          <PopoverTrigger>
            <IconButton variant="ghost" aria-label={"Delete"}>
              <TrashIcon className="w-5 h-5" />
            </IconButton>
          </PopoverTrigger>
          <Portal>
            <PopoverContent>
              <PopoverArrow />
              <PopoverCloseButton />
              <PopoverHeader>Confirmation!</PopoverHeader>
              <PopoverBody>
                <Text> Are you sure you wish to delete this template?</Text>
              </PopoverBody>
              <PopoverFooter>
                <Button
                  colorScheme="red"
                  variant="solid"
                  isLoading={deleteTemplateLoading}
                  onClick={() =>
                    deleteTemplate({
                      variables: {
                        deleteTemplateId: template.id,
                      },
                    })
                  }
                >
                  Delete
                </Button>
              </PopoverFooter>
            </PopoverContent>
          </Portal>
        </Popover>
      </ButtonGroup>
    </div>
  );
}
