import { useQuery } from "@apollo/client";
import {
  Button,
  Card,
  CardBody,
  IconButton,
  Text,
  Tooltip,
  useClipboard,
} from "@chakra-ui/react";
import { CheckIcon, ClipboardDocumentIcon } from "@heroicons/react/24/solid";
import { da } from "date-fns/locale";
import { useState } from "react";
import { GET_ORGANISATION } from "src/apollo/organisation";

export function ScriptCopy({
  completeStep,
  color,
  openAt,
  closedAt,
  openedDays,
}: any) {
  // get organisation ID
  const { data } = useQuery(GET_ORGANISATION);
  const getTimezone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone;
  };

  console.log(openedDays);

  const { onCopy, value, setValue, hasCopied } = useClipboard(
    `<script>
      window.WebChatConfig = {
        organisationId: '1db2e22a-746e-4324-98df-572c5996e5da',
        color: '${color.slice(1)}',
        timezone: '${getTimezone()}',
        openAt: '${openAt}',
        closedAt: '${closedAt}',
        openedDays: [${openedDays.map((day: string) => `'${day}'`)}],
      };
    </script>
    <script src="https://cdn.jsdelivr.net/gh/SmartyrBiz/Inbox-Webchat@main/dist/assets/app-Cc5q8XU2.js"></script>`
  );
  return (
    <div className="flex flex-col gap-2">
      <div className="border p-4 rounded-lg relative">
        <Text mb={1} wordBreak={"break-all"}>
          {`<script>window.WebChatConfig={organisationId:'1db2e22a-746e-4324-98df-572c5996e5da',color:'${color.slice(
            1
          )}',timezone:'${getTimezone()}',openAt:'${openAt}',closedAt:'${closedAt}',openedDays:[${openedDays.map(
            (day: string) => `'${day}'`
          )}]};</script>`}
        </Text>
        <Text mb={1}>
          {`<script src="https://cdn.jsdelivr.net/gh/SmartyrBiz/Inbox-Webchat@main/dist/assets/app-Cc5q8XU2.js"></script>`}
        </Text>
        <Tooltip placement="left" label={"Copy to clipboard"}>
          <IconButton
            aria-label="Copy to clipboard"
            variant="ghost"
            position="absolute"
            right="1"
            top="1"
            onClick={() => {
              onCopy();
              completeStep(3);
            }}
          >
            {hasCopied ? (
              <CheckIcon className="w-4 h-4" />
            ) : (
              <ClipboardDocumentIcon className="w-5 h-5" />
            )}
          </IconButton>
        </Tooltip>
      </div>
      <Button
        onClick={() => {
          onCopy();
          completeStep(3);
        }}
        variant={"outline"}
      >
        {hasCopied ? "Copied" : "Copy"}
      </Button>
    </div>
  );
}
